import React from "react"
import Layout from "../components/Layout"
import { Link } from "gatsby"
import { graphql } from "gatsby"
//import TitleOther from "../components/TitleOther"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import SEO from "../components/SEO"

const Myboat = ({
  data: {
    myboat: { nodes },
  },
}) => {
  const { info, title, image } = nodes[0]
  const image2 = getImage(image)
  return (
    <Layout>
      <SEO title="My Boat" description="about webdev" />
      <section className="about-page">
        <div className="section-center about-left">
            <a className="boat-enlarge" href="#sailing">Enlarge</a>
            <GatsbyImage image={image2} className="myboat-img" alt="boat"/>
            <article className="myboat-text">  
            <h3>{title}</h3>
            <div dangerouslySetInnerHTML={{__html: info.childMarkdownRemark.html} } />
             <ul>
             <li>Manufactured: 2008, <a id="nagelnieuw-1" href="#nagelnieuw">commissioning at Drimmelen</a></li>
             <li>Engine: Yanmar 75hp</li>
             <li>Length / width / height : 13.75m / 4.37m / 20.00m</li>
             <li>Furling mainsail : 36.9m² Tape-Drive</li>
             <li>Genoa 140% : 53m² Tape-Drive</li>
             <li>Design: P. Briand / V. Garroni</li>
             <li>Navigational equipment:<br />     
             &nbsp; &nbsp; o &nbsp; Wind-, depth- and speedometer Raymarine<br />     
             &nbsp; &nbsp; o &nbsp; Twin wheel steering, twin compas<br />     
             &nbsp; &nbsp; o &nbsp; Raymarine G3 Autopilot<br />     
             &nbsp; &nbsp; o &nbsp; VHF radio<br />     
             &nbsp; &nbsp; o &nbsp; AIS receiver and NAVTEX<br />
             &nbsp; &nbsp; o &nbsp; BR24 Broadband Radar<br />     
             &nbsp; &nbsp; o &nbsp; Simrad NX40 chart plotter<br />    
             &nbsp; &nbsp; o &nbsp; Intel NUC + 15.6" USB display (chart/AIS/radar/instruments/compass)<br />     
             &nbsp; &nbsp; o &nbsp; Laptop (chart/AIS/radar/instruments/compass)<br />     
             &nbsp; &nbsp; o &nbsp; Bow thruster factory-built</li>
             </ul>
  <div class="lightbox" id="sailing"><a href="#sailing-1"><span style={{backgroundImage: `url(/sailing04.jpg)`}}></span></a></div>
  <div class="lightbox" id="nagelnieuw"><a href="#nagelnieuw-1"><span style={{backgroundImage: `url(/nagelnieuw.jpg)`}}></span></a></div>
             <ul>
             <li>4x Victron 130W solar panels and 1x Air X 350W wind generator</li>
             <li>Webasto diesel central heating system (2 units, 8500W)</li>
             <li>615L water storage and 40L boiler, 2 toilets/showers (1 converted to storage room)</li>
             <li>4 unit cooking system (gas) with oven</li>
             <li>Max. passengers: 13</li>
             <li>2.3m dinghy + Mercury 2.5hp outboard</li>
             <li>Max. passengers with sleeping berth: 8</li>
             </ul>
             <h3>Maintenance log</h3>
             <div className="about-left"><a href="https://github.com/JeroenAdam/boat-maintenance-log#2008" target="_blank"
              rel="noopener noreferrer">external link</a></div>
          </article>
        </div>
        <Link to="/" className="btn center-btn">
          go back
        </Link>
      </section>
    </Layout>
  )
}

export const query = graphql`
  {
    myboat: allContentfulMyboat {
      nodes {
        title
        info {
          childMarkdownRemark {
            html
          }
        }
        image {
          gatsbyImage(width: 1100)
        }
      }
    }
  }
`

export default Myboat
