import React from "react"
import PropTypes from "prop-types"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { FaGithubSquare, FaShareSquare, FaLongArrowAltLeft } from "react-icons/fa"
import { Link } from 'gatsby'

const Project = ({ description, title, status, github, stack, url, image, index }) => {
  const image2 = getImage(image)
  return (
    <article id={title.replace(/ /g,"-").replace("/","").replace("--","-").toLowerCase()} className="project">
      {image && ( <GatsbyImage image={image2} className="project-img" alt="project"/> )}
      <div className="project-info">
        <span className="project-number">0{index + 1}.</span>
        <h3>{title || "default title"}</h3>
        <p className="job-date">{status}</p>
        <div className="project-desc" dangerouslySetInnerHTML={{__html: description.childMarkdownRemark.html} } />
              <div className="project-desc"><strong>Tech stack</strong>:</div>
              <div class="project-stack">
                {stack.map(({ node }, i) => (
                  <span><div key={i}>{stack[i]}</div></span>
                ))}
              </div>
        <div className="project-links">
          {github && ( <a href={github} target="_blank" rel="noopener noreferrer" aria-label="Github"> <FaGithubSquare className="project-icon" /> </a> )}
          {url && url==="../video" && ( <Link to={url}> <FaShareSquare className="project-icon" aria-label="Video"/> </Link> )}
          {url && url!=="../video" && ( <a href={url} target="_blank" rel="noopener noreferrer" aria-label="Website"> <FaShareSquare className="project-icon" /> </a> )}
          {url && ( <FaLongArrowAltLeft className="job-date"/> )}
          {url && ( <span> demo</span> )}
        </div>
      </div>
    </article>
  )
}

Project.propTypes = {
  title: PropTypes.string.isRequired,
  github: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
  stack: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default Project
