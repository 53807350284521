import React from "react"
import Layout from "../components/Layout"
import { Link } from "gatsby"

const Video = () => {
  return (
    <Layout>
      <section className="projects-page">
        <div><br/>
          <center>
          <h4>Custom project management tool integrated with RocketChat</h4>
          <video src="../custom-pmtool.mp4" type="video/mp4" width="960" height="530" controls></video>
          </center>
          <Link to="/projects#collaboration-workspace" className="btn center-btn">
            go back
          </Link>
        </div><br/><br/>
      </section>
    </Layout>
  )
}

export default Video
