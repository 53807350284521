import React from "react"
import Layout from "../components/Layout"
import { Link } from "gatsby"
import { graphql } from "gatsby"
import Blogs from "../components/Blogs"
import SEO from "../components/SEO"

const Blog = ({
  data: {
    allContentfulBlog: { nodes: blogs },
  },
}) => {
  return (
    <Layout>
      <SEO title="Blog" />
      <section className="blog-page">
        <Blogs blogs={blogs} title="blog" />
        <div className="section bg-grey">
        <Link to="/" className="btn center-btn">
        go back
        </Link>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
{
  allContentfulBlog(sort: { fields: created, order: DESC }) {
     
      nodes {
        id
        slug
        content {
          content
        }
        title
        desc
        created
        category
        image {
          gatsbyImage(width: 500)
        }
      }
    
  }
}
`
export default Blog
