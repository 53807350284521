import React from "react"
import {
  FaLinkedin,
  FaSkype,
  FaGithub,
  FaDev,
} from "react-icons/fa"

const data = [
  {
    id: 1,
    icon: <FaLinkedin className="social-icon" aria-label="Linkedin account"></FaLinkedin >,
    url: "https://www.linkedin.com/in/jeroen-devenijn/",
  },
  {
    id: 2,
    icon: <FaSkype className="social-icon" aria-label="Skype account"></FaSkype>,
    url: "https://join.skype.com/invite/aaURqjoe5oK0",
  },
  {
    id: 3,
    icon: <FaGithub  className="social-icon" aria-label="Github profile"></FaGithub>,
    url: "https://github.com/JeroenAdam",
  },
  {
    id: 4,
    icon: <FaDev className="social-icon" aria-label="Dev.to website"></FaDev>,
    url: "https://dev.to/jeroenadam",
  },
]
const links = data.map(link => {
  return (
    <li key={link.id}>
      <a href={link.url} className="social-link" target="_blank" rel="noopener noreferrer">
        {link.icon}
      </a>
    </li>
  )
})

export default ({ styleClass }) => {
  return (
    <ul className={`social-links ${styleClass ? styleClass : ""}`}>{links}</ul>
  )
}
