import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import Title from "../components/Title"
import Carousel from 'react-bootstrap/Carousel'
import { Link } from "gatsby"
import slide01 from "../assets/slides/marinasalex.jpg"
import slide02 from "../assets/slides/sale-marina.jpg"
import slide03 from "../assets/slides/kenitrax.jpg"
import slide04 from "../assets/slides/dakhla-mouillagex.jpg"
import slide05 from "../assets/slides/moroccox.jpg"
import slide06 from "../assets/slides/gibraltarx.jpg"
import slide07 from "../assets/slides/tangerportx.jpg"
import slide08 from "../assets/slides/smir2.jpg"
import slide09 from "../assets/slides/albughaz2017x.jpg"
import slide10 from "../assets/slides/tanger2017x.jpg"
import slide11 from "../assets/slides/sailing03x.jpg"
import slide12 from "../assets/slides/ciesx.jpg"
import slide13 from "../assets/slides/biscayx.jpg"
import slide14 from "../assets/slides/algarvex.jpg"
import slide15 from "../assets/slides/maintenancex.jpg"
import slide16 from "../assets/slides/rabatx.jpg"
import slide17 from "../assets/slides/lifeaboard4x.jpg"
import slide18 from "../assets/slides/lifeaboard4x.jpg"
import slide19 from "../assets/slides/lifeaboard9x.jpg"
import slide20 from "../assets/slides/lifeaboard9x.jpg"


const Myvoyages = () => {
    return (
      <Layout>
      <SEO title="My sailing voyages" />
      <div className="projects-page"><section className="section projects">
      <Title title="My sailing voyages" />
      <div className="section-center projects-center">
      <p>During the summer of 2012 I departed from Belgium. I experienced some fantastic sailing in France, Spain and Portugal. Two months later, I arrived in Morocco. I'm now living with my wife and children, two steps away from the Moroccan capital, in Salé.</p>
      <p></p>
      </div>
      <Carousel>
      <Carousel.Item>
         <img width="1168" class="center-slides" src={slide01} alt="" />
         <Carousel.Caption><h4>I sailed from Belgium to Morocco and started a new life in Morocco.</h4></Carousel.Caption>
       </Carousel.Item>
       <Carousel.Item>
         <img width="1168" class="center-slides" src={slide02} alt="" />
         <Carousel.Caption><h4>I'm now berthed two steps away from the Moroccan capital city, in Salé.</h4></Carousel.Caption>
       </Carousel.Item>
       <Carousel.Item>
         <img width="1168" class="center-slides" src={slide03} alt="" />
         <Carousel.Caption><h4>North of my current position I have been navigating upriver to the city of Kenitra.</h4></Carousel.Caption>
       </Carousel.Item>
       <Carousel.Item>
         <img width="1168" class="center-slides" src={slide04} alt="" />
         <Carousel.Caption><h4>South of my current position awaits an amazing surrounding.... Dakhla. When? Allahu a3lam.</h4></Carousel.Caption>
       </Carousel.Item>
       <Carousel.Item>
         <img width="1168" class="center-slides" src={slide05} alt="" />
         <Carousel.Caption><h4>A past holiday: North-Morocco and Spain.</h4></Carousel.Caption>
       </Carousel.Item>
       <Carousel.Item>
         <img width="1168" class="center-slides" src={slide06} alt="" />
         <Carousel.Caption><h4>I rented a berth right next to Jebel Tariq (Gibraltar).</h4></Carousel.Caption>
       </Carousel.Item>
       <Carousel.Item>
         <img width="1168" class="center-slides" src={slide07} alt="" />
         <Carousel.Caption><h4>One year later, I toured in North-Morocco (Tanger).</h4></Carousel.Caption>
       </Carousel.Item>
       <Carousel.Item>
         <img width="1168" class="center-slides" src={slide08} alt="" />
         <Carousel.Caption><h4>And navigated further to Marina Smir.</h4></Carousel.Caption>
       </Carousel.Item>
       <Carousel.Item>
         <img width="1168" class="center-slides" src={slide09} alt="" />
         <Carousel.Caption><h4>Last summer, North-Morocco again (Smir)...</h4></Carousel.Caption>
       </Carousel.Item>
       <Carousel.Item>
         <img width="1168" class="center-slides" src={slide10} alt="" />
         <Carousel.Caption><h4>This time with both children (Tanger).</h4></Carousel.Caption>
       </Carousel.Item>
       <Carousel.Item>
         <img width="1168" class="center-slides" src={slide11} alt="" />
         <Carousel.Caption><h4>This is an experience I cannot describe in a few words...</h4></Carousel.Caption>
       </Carousel.Item>
       <Carousel.Item>
         <img width="1168" class="center-slides" src={slide12} alt="" />
         <Carousel.Caption><h4>At times you reach places where no car can come (Cies).</h4></Carousel.Caption>
       </Carousel.Item>
       <Carousel.Item>
         <img width="1168" class="center-slides" src={slide13} alt="" />
         <Carousel.Caption><h4>But sometimes the sea gets rough (Biscay).</h4></Carousel.Caption>
       </Carousel.Item>
       <Carousel.Item>
         <img width="1168" class="center-slides" src={slide14} alt="" />
         <Carousel.Caption><h4>At other times there is no better place to be (Algarve).</h4></Carousel.Caption>
       </Carousel.Item>
       <Carousel.Item>
         <img width="1168" class="center-slides" src={slide15} alt="" />
         <Carousel.Caption><h4>And sometimes the boat keeps you busy with... maintenance.</h4></Carousel.Caption>
       </Carousel.Item>
       <Carousel.Item>
         <img width="1168" class="center-slides" src={slide16} alt="" />
         <Carousel.Caption><h4>I'm now living aboard with my family...</h4></Carousel.Caption>
       </Carousel.Item>
       <Carousel.Item>
         <img width="1168" class="center-slides" src={slide17} alt="" />
         <Carousel.Caption><h4>As we have been living for the past 7 years.</h4></Carousel.Caption>
       </Carousel.Item>
       <Carousel.Item>
         <img width="1168" class="center-slides" src={slide18} alt="" />
         <Carousel.Caption><h4></h4></Carousel.Caption>
       </Carousel.Item>
       <Carousel.Item>
         <img width="1168" class="center-slides" src={slide19} alt="" />
         <Carousel.Caption><h4>>>> "I don't know much about the sea yet, but I like being out there."</h4></Carousel.Caption>
       </Carousel.Item>
       <Carousel.Item>
         <img width="1168" class="center-slides" src={slide20} alt="" />
         <Carousel.Caption><h4></h4></Carousel.Caption>
       </Carousel.Item><Carousel.Item>
         <img class="center-slides" src={slide01} alt="" />
         <Carousel.Caption><h4>I sailed from Belgium to Morocco and I have started a new life in Morocco.</h4></Carousel.Caption>
       </Carousel.Item>
       <Carousel.Item>
         <img class="center-slides" src={slide02} alt="" />
         <Carousel.Caption><h4>I'm now berthed two steps away from the Moroccan capital city, in Salé.</h4></Carousel.Caption>
       </Carousel.Item>
       <Carousel.Item>
         <img class="center-slides" src={slide03} alt="" />
         <Carousel.Caption><h4>North of my current position I have been navigating upriver to the city of Kenitra.</h4></Carousel.Caption>
       </Carousel.Item>
       <Carousel.Item>
         <img class="center-slides" src={slide04} alt="" />
         <Carousel.Caption><h4>South of my current position awaits an amazing surrounding.... Dakhla. When? Allahu a3lam.</h4></Carousel.Caption>
       </Carousel.Item>
       <Carousel.Item>
         <img class="center-slides" src={slide05} alt="" />
         <Carousel.Caption><h4>A past holiday: North-Morocco and Spain.</h4></Carousel.Caption>
       </Carousel.Item>
       <Carousel.Item>
         <img class="center-slides" src={slide06} alt="" />
         <Carousel.Caption><h4>I rented a berth right next to Jebel Tariq (Gibraltar).</h4></Carousel.Caption>
       </Carousel.Item>
       <Carousel.Item>
         <img class="center-slides" src={slide07} alt="" />
         <Carousel.Caption><h4>One year later, I toured in North-Morocco (Tanger).</h4></Carousel.Caption>
       </Carousel.Item>
       <Carousel.Item>
         <img class="center-slides" src={slide08} alt="" />
         <Carousel.Caption><h4>And navigated further to Marina Smir.</h4></Carousel.Caption>
       </Carousel.Item>
       <Carousel.Item>
         <img class="center-slides" src={slide09} alt="" />
         <Carousel.Caption><h4>Last summer, North-Morocco again (Smir)...</h4></Carousel.Caption>
       </Carousel.Item>
       <Carousel.Item>
         <img class="center-slides" src={slide10} alt="" />
         <Carousel.Caption><h4>This time with both children (Tanger).</h4></Carousel.Caption>
       </Carousel.Item>
       <Carousel.Item>
         <img class="center-slides" src={slide11} alt="" />
         <Carousel.Caption><h4>This is an experience I cannot describe in a few words...</h4></Carousel.Caption>
       </Carousel.Item>
       <Carousel.Item>
         <img class="center-slides" src={slide12} alt="" />
         <Carousel.Caption><h4>At times you reach places where no car can come (Cies).</h4></Carousel.Caption>
       </Carousel.Item>
       <Carousel.Item>
         <img class="center-slides" src={slide13} alt="" />
         <Carousel.Caption><h4>But sometimes the sea gets rough (Biscay).</h4></Carousel.Caption>
       </Carousel.Item>
       <Carousel.Item>
         <img class="center-slides" src={slide14} alt="" />
         <Carousel.Caption><h4>At other times there is no better place to be (Algarve).</h4></Carousel.Caption>
       </Carousel.Item>
       <Carousel.Item>
         <img class="center-slides" src={slide15} alt="" />
         <Carousel.Caption><h4>And sometimes the boat keeps you busy with... maintenance.</h4></Carousel.Caption>
       </Carousel.Item>
       <Carousel.Item>
         <img class="center-slides" src={slide16} alt="" />
         <Carousel.Caption><h4>I'm now living aboard with my family...</h4></Carousel.Caption>
       </Carousel.Item>
       <Carousel.Item>
         <img class="center-slides" src={slide17} alt="" />
         <Carousel.Caption><h4>As we have been living for the past 7 years.</h4></Carousel.Caption>
       </Carousel.Item>
       <Carousel.Item>
         <img class="center-slides" src={slide18} alt="" />
         <Carousel.Caption><h4 style={{color:"--clr-grey-10"}}>As we have been living for the past 7 years.</h4></Carousel.Caption>
       </Carousel.Item>
       <Carousel.Item>
         <img class="center-slides" src={slide19} alt="" />
         <Carousel.Caption><h4>>>> "I don't know much about the sea yet, but I like being out there."</h4></Carousel.Caption>
       </Carousel.Item>
       <Carousel.Item>
         <img class="center-slides" src={slide20} alt="" />
         <Carousel.Caption><h4 style={{color:"--clr-grey-10"}}>>>> "I don't know much about the sea yet, but I like being out there."</h4></Carousel.Caption>
       </Carousel.Item>
       </Carousel>
       <h2>&nbsp; </h2>
       <Link to="/blog" className="btn center-btn">
          my blog
        </Link>
       </section></div>
       </Layout>
  )
}

export default Myvoyages

