import React from "react"
import Layout from "../components/Layout"
import { Link } from "gatsby"
import { graphql } from "gatsby"
import Projects from "../components/Projects"
import SEO from "../components/SEO"

const ProjectsPage = ({
  data: {
    projects: { nodes: projects },
  },
}) => {
  return (
    <Layout>
      <SEO title="Projects" />
      <section className="projects-page">
        <Projects projects={projects} title="my projects" />
         <Link to="/" className="btn center-btn">
          Go back
         </Link>
      </section>
    </Layout>
  )
}
export const query = graphql`
    { 
      projects: allContentfulProjects (sort: { fields: order, order: ASC }) {
        nodes {
          title
          featured
          status
          description {
            childMarkdownRemark {
              html
            }
          }
          github
          url
          stack
          image {
            gatsbyImage(width: 1100)
          }

        }
      }
    }  
`

export default ProjectsPage
