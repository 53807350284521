import React from "react"
import { Link } from "gatsby"
import { FaHome, FaUserCircle, FaBrain } from "react-icons/fa"
import { GiShipWheel } from "react-icons/gi"
import { BiNews } from "react-icons/bi"
import { AiOutlineMail } from "react-icons/ai"
import { RiSailboatFill } from "react-icons/ri"

const data = [
  {
    id: 1,
    text: "home",
    url: "/",
    icon: <FaHome></FaHome>,
  },
  {
    id: 2,
    text: "about",
    url: "/about",
    icon: <FaUserCircle></FaUserCircle>,
  },
  {
    id: 3,
    text: "projects",
    url: "/projects",
    icon: <FaBrain></FaBrain>,
  },
  {
    id: 4,
    text: "my voyages",
    url: "/myvoyages" ,
    icon: <GiShipWheel></GiShipWheel>,
  },
  {
    id: 5,
    text: "blog",
    url: "/blog",
    icon: <BiNews></BiNews>,
  },
  {
    id: 6,
    text: "my boat",
    url: "/myboat",
    icon: <RiSailboatFill></RiSailboatFill>,
  },
  {
    id: 7,
    text: "contact",
    url: "/contact",
    icon: <AiOutlineMail></AiOutlineMail>,
  },
]

const tempLinks = data.map(link => {
  return (
    <li key={link.id}>
      <Link to={link.url}>{link.icon} &nbsp;{link.text}</Link>
    </li>

  )
})
// I KNOW WE CAN COMBINE IT !!!!!

export default ({ styleClass }) => {
  return (
    <ul className={`page-links ${styleClass ? styleClass : ""}`}>
      {tempLinks}
    </ul>
  )
}
