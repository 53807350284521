import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import SocialLinks from "../constants/socialLinks"
const Hero = () => {
  return (
    <header className="hero">
      <div className="section-center hero-center">
        <article className="hero-info">
          <div>
            <div className="hero-info-arabic"><h1>السلام عليكم</h1></div>
            <h1>Welcome</h1>
            <p>&nbsp;</p>
            <div className="underline"></div> 
            <p>&nbsp;</p>
            <h2>I'm Jeroen Adam</h2>
            <p>&nbsp;</p>
            <h4>IT generalist, software developer</h4>
            <h5>(and working remotely aboard my sailboat)</h5>
            <Link to="/projects" className="btn">
              my projects
            </Link>
            <SocialLinks />
          </div>
        </article>
        <div className="hero-img">  <StaticImage src="../assets/hero-img.avif" objectFit ="scale-down" className="hero-img" alt="me"/></div>
      </div>
    </header>
  )
}

export default Hero
