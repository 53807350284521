import React from "react"
import Title from "./Title"
import { FaAngleDoubleRight } from "react-icons/fa"
import { graphql, useStaticQuery } from "gatsby"
import ReactMarkdown from "react-markdown"

const query = graphql`
{
  allContentfulJobs(sort: { fields: createdAt, order: DESC }) {
    nodes {
      contentful_id
      company
      date
      position
      desc
    }
  }
}
`

const JobsOther = (showLink) => {
  const data = useStaticQuery(query)
  const {
    allContentfulJobs: { nodes: jobs },
  } = data
  const [value, setValue] = React.useState(0)
  const { company, position, date, desc } = jobs[value]

  return (
    <section className="section jobs">
      <Title title="experience" />
      <div className="jobs-center">
        {/* btn container */}
        <div className="btn-container">
          {jobs.map((item, index) => {
            return (
              <button
                key={item.contentful_id}
                onClick={() => setValue(index)}
                className={`job-btn ${index === value && "active-btn"}`}
              >
                {item.company}
              </button>
            )
          })}
        </div>
        {/* job info */}
        <article className="job-info">
          <h3>{position}</h3>
          <h4>{company}</h4>
          <p className="job-date">{date}</p>
          <div>
                {desc.map(({ node }, i) => (
                  <div className="job-desc" key={i}>
                  <FaAngleDoubleRight className="job-icon"></FaAngleDoubleRight>
                  <ReactMarkdown children={desc[i]} />
                  </div>
                ))}
          </div>
        </article>
        </div>
                    
    </section>
  )
}

export default JobsOther
