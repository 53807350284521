import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import { FaCalendarAlt } from "react-icons/fa"
import { AnchorLink } from "gatsby-plugin-anchor-links"


const ComponentName = ({ data }) => {
  const { slug, content, title, created, desc } = data.blog

  return (
    <Layout>
      <SEO title={title} description={desc} />
      <section className="blog-template">
        <div className="section-center">
          <article className="blog-content">
            <h2>{title}</h2>
            <div className="blog-template-date">
            <FaCalendarAlt />&nbsp; {created}
            </div>
            <p></p>
            <div dangerouslySetInnerHTML={{__html: content.childMarkdownRemark.html} } />
          </article>
          <AnchorLink to={`/blog#${slug}`} className="btn center-btn">Go Back</AnchorLink>         
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query GetSingleBlog($slug: String) {
    blog: contentfulBlog(slug: { eq: $slug }) {
      content {
        childMarkdownRemark {
          html
        }
      }
      slug
      title
      created
    }
  }
`

export default ComponentName
