import React from "react"
import Layout from "../components/Layout"

const Skillmatrix = () => {
  return (
    <Layout>
      <section className="blog-template section jobs">
        <div><br/>
          <center>
          <h3>SD Skill Matrix</h3>
          <br/><br/>
          <div class="project-desc">
          <p>A self-help tool I created for Service Desk agents during my 5y. in IT support...</p>
          <p>...to help SD agents develop their skillset managing large IT environments</p>
          <p><a href="https://github.com/JeroenAdam/skillmatrix/blob/master/SD-skill-matrix" target="_blank" rel="noopener noreferrer">https://github.com/...</a></p>
          </div><br/><br/>
          </center>
         <a href="/#experience" target="_self" className="btn center-btn">
           go back
         </a>
        </div><br/><br/><br/><br/>
      </section>
    </Layout>
  )
}

export default Skillmatrix
