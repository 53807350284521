import React from "react"
import Layout from "../components/Layout"

const Skillmatrix = () => {
  return (
    <Layout>
      <section className="blog-template section jobs">
        <div><br/>
          <center>
          <h3>My bookkeeping app</h3>
          <br/><br/>
          <div class="project-desc">
          <p>I coded this app (PHP) 20 years ago...</p>
          <p>...to serve as a bookkeeping tool for my business</p>
          <p><a href="https://github.com/JeroenAdam/DIY-bookkeeping-app#my-diy-bookkeeping-app" target="_blank" rel="noopener noreferrer">https://github.com/...</a></p>
          </div><br/><br/>
          </center>
         <a href="/#selfemployed" target="_self" className="btn center-btn">
           go back
         </a>
        </div><br/><br/><br/><br/>
      </section>
    </Layout>
  )
}

export default Skillmatrix
